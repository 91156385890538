a {
	color: $link-color;
	position: relative;
	transition: background-color .1s, border .2s, color .2s;
	z-index: 10;

	&:hover,
	&:focus {
		color: $link-color-hover;
		text-decoration: underline;
	}
	&.no-deco {

		&:hover,
		&:focus {
			color: $link-color-hover;
			text-decoration: none;
		}
	}
}
@media print {
  a[href]:after {
    content: none !important;
  }
}
body {
	line-height: 1.4em;
}
hr {
	border-color: $color-lt-gray;
	margin-bottom: 1em;
	margin-top: 1em;
}
// .anchor {
// 	z-index: 0;
//
// 	&:before {
// 		content: "";
// 		display: block;
// 		height: 130px;
// 		margin-top: -130px;
// 	}
// }
.clear-left {
	clear: left !important;
}
.clear-right {
	clear: right !important;
}
.container {
	@include clearfix;
	position: relative;
}
.margin-none {
	margin: 0 !important;
}
.padding-none {
	padding: 0 !important;
}
.padding-bottom-none {
	padding-bottom: 0 !important;
}
.padding-top-none {
	padding-top: 0 !important;
}
