footer {
	border-top: 1px solid $color-lt-gray;
	color: $color-dk-gray;
	font-size: $font-size-sm;
	padding-top: 1em;
	text-align: center;
}
.uh-footer {
	padding-bottom: 1em;

	a {
		color: $color-uh-red;
		padding: 0 10px;
	}
	.nav-required {

		li {
			display: inline-block;
		}
	}
}
