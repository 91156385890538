.staff {
	text-align: center;

	.person {
		min-height: 19em; // prevents column hanging
	}
	.photo {
		border: 1px solid $color-lt-gray;
		border-radius: 50%;
		width: 120px;
	}
}
.staff-map {
	padding: 0;

	a {
		background-color: #d3e0ff;
		background-image: url('/assets/images/staff-map.jpg');
		background-position: center 5em;
		@media (min-width: 1200px) {
			background-position: center 1em;
		}
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: contain;
		display: block;
		height: 280px;
	}
}
