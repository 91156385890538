.features {

	h3 {
		font-size: 1.6em;
	}
	.feature {
		background-color: $color-almost-white;
		border-color: #fff;
		border-style: solid;
		border-width: 2px 1px;
		overflow: hidden;
		padding-top: 1em;
		text-align: center;
		transition: background-color .5s, border .2s;

		&:hover {
			background-color: #fff;
			border-bottom-color: $link-color-hover;
		}
		.image-wrap {
			background-position: center bottom;
			background-repeat: no-repeat;
			background-size: 315px;
			@media (min-width: 375px) {
				background-size: 370px;
			}
			@media (min-width: 768px) {
				background-size: 240px;
			}
			@media (min-width: 992px) {
				background-size: 310px;
			}
			@media (min-width: 1200px) {
				background-size: 405px;
			}
			height: 125px;
			@media (min-width: 375px) {
				height: 145px;
			}
			@media (min-width: 768px) {
				height: 90px;
			}
			@media (min-width: 992px) {
				height: 130px;
			}
			@media (min-width: 1200px) {
				height: 155px;
			}
		}
	}
	a:hover,
	a:focus {
		background-color: #fff;
		border-bottom: 2px solid #fff;
	}
}
