header {
	background-color: #fff;
	border-bottom: 1px solid $link-color;
	padding-top: 20px;
	position: relative;
	width: 100%;
	z-index: 100;
	@media (min-width: 768px) {
		border-bottom: none;
	}

	.links {
		color: $color-dk-gray;
		float: right;
		font-size: $font-size-sm;
		margin-top: -.5em;
		text-align: center;
		z-index: 1000;
	}

	a {
		color: $color-dk-gray;

		&:hover {
			color: inherit;
		}
	}
	.chat {
		background-color: #00b388;
		border: 1px solid #00b388;
		border-radius: 2px;
		color: #fff !important;
		padding: 2px 5px 1px;
		text-decoration: none;

		&:hover {
			background-color: #fff;
			border: 1px solid #00b388;
			color: #00b388 !important;
		}
	}
	.scroll-line {
		border-bottom: 2px $color-uh-teal solid;
		display: none;
		@media (min-width: 768px) {
			display: inherit;
		}
		margin-bottom: -1px;
		width: 0;
	}
}
.contact .scroll-line, .home .scroll-line, .news-events .scroll-line {
	display: none;
}
