.navbar-default {
	background-color: $color-uh-red;
	border: none;
	border-radius: 0;
	margin-bottom: 0;
	margin-top: 90px;
	@media (min-width: 768px) {
		margin-top: 80px;
	}

	min-height: inherit;

	.navbar-brand {
		color: $link-color;
		font-size: 2em;

		&:hover {
			color: $link-color-hover;
			text-decoration: underline;
		}
		& > img {
			display: inline;
			margin-right: 10px;
			vertical-align: middle;
		}
	}
	.navbar-header {
		float: none;
		position: absolute;
		top: -130px;
		width: 100%;
		@media (min-width: 768px) {
			top: -125px;
			width: auto;
		}

		.navbar-toggle {
			margin-top: 45px;
		}
	}
	.navbar-nav {
		float: none;
		margin-bottom: 0;
		margin-top: 0;
		@media (min-width: 768px) {
			margin-left: -30px;
			margin-right: -30px;
		}

		li {
			float: none;
			-webkit-font-smoothing: subpixel-antialiased;
			@media (min-width: 768px) {
				display: inline-block;
				margin-right: -.25em;
			}

			a {
				color: #fff;
				padding: 15px;
			}
			a:hover, &.active a, &.active a:hover {
				background-color: $color-uh-brick;
				color: #fff;
			}
		}
	}
}
