.banner {

	.image {
		max-width: 1280px;
		width: 100%;
	}
	ul {
		margin-bottom: 0;
	}
	li {
		margin-bottom: 0;
	}
}
