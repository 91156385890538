.img-hero {
	background-repeat: no-repeat;
	background-size: cover;
	height: 515px;
	overflow: hidden;
	position: relative;
	transition: transform 1s ease-in-out;

	&.left {
		background-position: right top;
		border-left: 1px solid $color-lt-gray;
		left: -50px;
		margin-left: -40px;

		&.animate {
			transform: translateX(20px);
		}
	}
	&.right {
		background-position: left top;
		border-right: 1px solid $color-lt-gray;
		margin-right: -40px;
		right: -50px;

		&.animate {
			transform: translateX(-20px);
		}
	}
}
