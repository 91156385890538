// tags
blockquote {
	border-left-color: $color-lt-gray;
}
cite:before {
	content: '\2013 \00A0';
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin-top: 0;
}
h1,
h2 {
	color: $color-dk-gray;
	font-weight: 200;
	line-height: 1.2em;
}
h1 {
	font-size: 34px;
	@media (min-width: 768px) {
		font-size: 43px;
	}
}
h2 .small {
	font-weight: 200;
}
h3 {
	font-weight: normal;
}
h4 {
	font-weight: normal;
}
li {
	margin-bottom: .25em;
}
p {
	line-height: inherit;
}
ul {
	margin-top: 0;
}

// classes
.block {
	margin-left: auto;
	margin-right: auto;
	max-width: 35em;
}
.head-xl {
	color: $color-uh-teal;
	font-size: 4.5em;
	position: absolute;
	margin-top: -.4em;
	text-align: center;
	text-shadow: 4px 5px 4px rgba(0,0,0,.2);
	top: 50%;
	width: 100%;
}
.lead {
	color: $color-dk-gray;
	font-size: 1.3em;
	line-height: 1.2em;
	@media (min-width: 768px) {
		font-size: 1.5em;
	}
}
small, .small {
	line-height: 1.4em;
}

// classes - colors
.gray {
	color: $color-md-gray;
}
.red {
	color: $color-uh-red;
}
