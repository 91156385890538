.striped section:nth-child(even) {
	background-color: #fff;
}
section {
	padding-bottom: 1em;
	padding-top: 1em;
	position: relative;

	.padding-none & {
		padding-bottom: 0;
		padding-top: 0;

		&:last-child {
			padding-bottom: 1em;
		}
	}
}
.intro {

	.anchor-nav {
		position: absolute;
		text-align: center;
		top: 10px;
		width: 100%;
	}
}
.mynsm-logo img {
	height: 28px;
	@media (min-width: 1200px) {
		height: 32px;
	}
}
